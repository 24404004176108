
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'

export default defineComponent({
  setup() {
    const loginType = ref('account')
    const username = ref('')
    const password = ref('')
    const loading = ref(false)

    const router = useRouter()

    const store = useStore()

    const onClickLoginTypeChange = () => {
      loginType.value === 'account'
        ? loginType.value = 'dingding'
        : loginType.value = 'account'
    }

    const handleLogin = async () => {
      try {

        if (username.value === '') {
          ElMessage.warning('用户名不能为空')
          return
        }
        if (password.value === '') {
          ElMessage.warning('密码不能为空')
          return
        }

        loading.value = true
        let homePageName = await store.dispatch('Login', {
          username: username.value,
          password: password.value
        })
        loading.value = false
        router.push({ path: `/${homePageName}` })
      } catch {
        loading.value = false
      }

    }

    return {
      loginType,
      username,
      password,
      loading,
      handleLogin,
      onClickLoginTypeChange
    }
  }
})
